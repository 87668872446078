import React from "react";

export default function SiderBar(){


    return <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-heading">Mon Espace</li>
            <li className="nav-item">
                <a className="nav-link collapsed" href="/home/meeting">
                    <i className="bi bi-journal-text"></i><span>Mes Rendez-vous</span>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link collapsed" href="/home/cp">
                    <i className="bi bi-layout-text-window-reverse"></i><span>Les Centres Partenaires</span>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link collapsed" href="/home/carte">
                    <i className="bi bi-layout-text-window-reverse"></i><span>Carte</span>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse"
                   aria-expanded="false">
                    <i className="bi bi-menu-button-wide"></i><span>Articles</span><i
                    className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="/home/work/SANTE_SEXUELLE">
                            <i className="bi bi-circle"></i><span>Sante Sexuele</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home/work/GROSSESSE_PRECOSE">
                            <i className="bi bi-circle"></i><span>Prevention des grossesses precoces</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home/work/HYGENE_MENSTRUELLE">
                            <i className="bi bi-circle"></i><span>Hygiene Menstruelle</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home/work/VIH_IST">
                            <i className="bi bi-circle"></i><span>VIH & IST</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home/work/DROGUE_STUPEFIANT">
                            <i className="bi bi-circle"></i><span>Drogues & Stupefiants</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home/work/VVG">
                            <i className="bi bi-circle"></i><span>Violence basee sur le genre</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home/work/IVG">
                            <i className="bi bi-circle"></i><span>Interruption Volontaire de grossesses</span>
                        </a>
                    </li>
                    <li>
                        <a href="/home/work/PLANNING_FAMILLIAL">
                            <i className="bi bi-circle"></i><span>Planning Familiale</span>
                        </a>
                    </li>

                </ul>
            </li>
            <li className="nav-item">
                <a className="nav-link collapsed" href="https://test.youthfp.cm/" target="_blank">
                    <i className="bi bi-layout-text-window-reverse"></i><span>e-learning</span>
                </a>
            </li>
        </ul>
    </aside>;
}