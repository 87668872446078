import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import articleImg from "../../img/BMD-3398-760x400.png";
import {useSelector} from "react-redux";
import {useTitle} from "../../../utils/Utils";

function ListArticles() {
    const {name} = useParams();
    useTitle("Articles");
    const [articles, setArticles] = useState([]);
    const {user, accessToken} = useSelector(state => state.authUser);
    const fetchArticle = ()=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        let dataList = ['SANTE_SEXUELLE','GROSSESSE_PRECOSE','HYGENE_MENSTRUELLE','VIH_IST','DROGUE_STUPEFIANT','VVG','IVG','PLANNING_FAMILLIAL']
        if(dataList.fill(name).length<=0){
            window.location.href='/home/meeting'
        }
        axiosInstance().get('api/article/get-by-categorie/'+ name)
            .then(function (response) {
                let data = response.data;
                let fetchedArticles = [];
                if (data != null) {
                    for (let i = 0; i < data.length; i++) {
                        try {
                            fetchedArticles.push(
                                <div className="col-lg-4 col-md-6 col-12" key={data[i].id}>
                                    <div className="card">
                                        <img src={articleImg} className="card-img-top" alt="" />
                                        <div className="card-img-overlay">
                                            <a className="btn btn-primary">Auteur: {data[i].author}</a>
                                            <h2 className="card-title">Titre: {data[i].title}</h2>
                                            <p className="card-title"><NavLink to={"/home/read/" + data[i].id}>Lire</NavLink></p>
                                        </div>
                                    </div>
                                </div>
                            );
                        } catch (e) {
                        }
                    }
                    setArticles(fetchedArticles);
                    preloader.classList.add("preloader-deactivate");
                } else {
                    fetchedArticles.push(
                        <div id="error">
                            <p>Il n'y a pas d'articles pour le moment</p>
                        </div>
                    );
                    setArticles(fetchedArticles);
                    preloader.classList.add("preloader-deactivate");
                }
            })
            .catch(function (error) {
                preloader.classList.add("preloader-deactivate");
            });
    }
    function checkIfUserIsConnect() {
        window.location='/';
    }
    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchArticle();
        },[]
    )
    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Articles</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">{name}</li>
                </ol>
            </nav>
        </div>
        <section className="section">
            <div className="row align-items-top">
                {
                    (articles.length === 0 ? (<></>) : articles)
                }
            </div>
        </section>

    </main>;

}

export default ListArticles;